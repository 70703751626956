






































import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import DataTable from '@/components/UI/Elements/DataTable/DataTable.vue'
import { Component, Vue } from 'vue-property-decorator'
import { GetApiAccessTokenModel } from 'truemarket-modules/src/models/api/api_access_tokens'
import { Services } from 'truemarket-modules/src/services'

@Component({
  components: {
    DataTable
  }
})
export default class ApiAccessIndex extends Vue {
  isShowingKey = false
  jsonWebToken = ''

  isAddingAccessKey = false
  clientName = ''
  keyName = ''

  apiAccessTokens: GetApiAccessTokenModel[] = []

  dtDef = new DataTableDef(new DataTableRowDef(row => row.ApiAccessTokenId, [
    new DataTableColDef('Client Name', row => row.ClientName),
    new DataTableColDef('Key Name', row => row.KeyName),
    new DataTableColDef('Create Date', row => `${row.CreateDate.FormattedDate} ${row.CreateDate.FormattedTime}`),
    new DataTableColDef('Active', row => row.IsActive ? 'Yes' : 'No')
  ]))

  mounted () {
    this.loadData()
  }

  loadData () {
    Services.API.APIAccessTokens.GetApiAccessTokens().then((apiAccessTokens) => {
      this.apiAccessTokens = apiAccessTokens
    })
  }

  deleteToken (apiAccessTokenId: string) {
    Services.API.APIAccessTokens.DeleteAPIAccessToken(apiAccessTokenId).then(() => {
      this.loadData()
    })
  }

  addToken () {
    Services.API.APIAccessTokens.CreateApiAccessToken({ ClientName: this.clientName, KeyName: this.keyName }).then(() => {
      this.loadData()

      this.isAddingAccessKey = false
      this.clientName = ''
      this.keyName = ''
    })
  }

  getToken (apiAccessTokenId: string) {
    Services.API.APIAccessTokens.GetApiJsonWebToken(apiAccessTokenId).then((token) => {
      this.isShowingKey = true
      this.jsonWebToken = token.AccessToken
    })
  }
}
